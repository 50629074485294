import styled from "@emotion/styled";
import { graphql } from "gatsby";
import type { PageProps } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import ProjectBadge from "../../components/ProjectBadge";
import breakpoints from "../../utils/breakpoints";
import useWindowSize from "../../utils/useWindowSize";
import i18n from "../../utils/i18n";

const ProjectsListPageStyles = styled.div`
  margin: 1rem;

  .project-list {
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;

    .project-flow-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
    }
  }
`;

type DataProps = {
  allSanityProject: {
    nodes: {
      _createdAt: string;
      _updatedAt: string;
      _id: string;
      project_id: {
        current: string;
      };
      name: string;
      icon: string | null;
    }[];
  };
};

export default function ProjectsPage(props: PageProps<DataProps>) {
  const projects = props.data.allSanityProject.nodes || [];

  return (
    <ProjectsListPageStyles>
      <div className="project-list">
        <h1>{i18n("projects.title")}</h1>
        <div className="project-flow-list">
          {projects.map((project) => (
            <AniLink
              fade
              key={project._id}
              to={`/projects/${project.project_id.current}`}
              aria-label={`${project.name}`}
            >
              <ProjectBadge
                name={project.name}
                project_id={project.project_id.current}
                icon={project.icon}
              />
            </AniLink>
          ))}
        </div>
      </div>
    </ProjectsListPageStyles>
  );
}

export const query = graphql`
  query {
    allSanityProject(sort: { order: DESC, fields: [_createdAt] }) {
      nodes {
        _createdAt
        _updatedAt
        _id
        project_id {
          current
        }
        name
        icon
      }
    }
  }
`;
