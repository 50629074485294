import React from "react";
import styled from "@emotion/styled";
import idToColour from "../utils/idToColour";
import isLight from "../utils/isLight";

interface ProjectBadgeProps {
  project_id: string;
  name: string;
  icon: string | null;
}

const ProjectBadgeStyles = styled.span<ProjectBadgeProps>`
  display: inline-flex;
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 30px;
  position: relative;
  overflow: hidden;

  box-shadow: 0px 12px 12px -12px black, 0px 0px 12px -6px black;

  background-color: ${(props) => idToColour(props.project_id)};
  span.project-initial {
    color: ${(props) =>
      isLight(idToColour(props.project_id)) ? "black" : "white"};
  }

  img.project-icon {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    object-fit: cover;
  }

  span.project-initial {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    font-size: 50px;
  }
`;

function ProjectBadge(
  props: ProjectBadgeProps & React.HTMLProps<HTMLDivElement>,
) {
  const { name, icon } = props;
  return (
    <ProjectBadgeStyles className="project-badge" {...props}>
      {icon ? (
        <img role="presentation" className="project-icon" src={icon} />
      ) : (
        <span role="presentation" className="project-initial">
          {name.substring(0, 1).toUpperCase()}
        </span>
      )}
    </ProjectBadgeStyles>
  );
}

export default ProjectBadge;
