import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export default atom({
  key: "lang",
  default: "en",
  effects: [persistAtom],
});
