import { useRecoilState } from "recoil";
import { getProperty } from "dot-prop";
import langAtom from "../atoms/lang";

const translations = {
  common: {
    updated: ["Updated", "更新日"],
  },
  projects: {
    title: ["Projects", "プロジェクト一覧"],
    created: ["Created", "製作日"],
    link_github: ["GitHub", "GitHub"],
    link_gitlab: ["Gitlab", "Gitlab"],
    link_docs: ["Documentation", "説明文書"],
    link_homepage: ["Homepage", "ホームページ"],
    link_article: ["Article", "記事"],
    link_other: ["Other", "その他"],
  },
  articles: {
    title: ["Articles", "記事一覧"],
    created: ["Created", "投稿日"],
  },
  tags: {
    title: ["Tags", "タグ一覧"],
    single: ["Tag", "タグ関連"],
    projects: [
      (count: number) => `${count} project${count > 1 ? "s" : ""}`,
      (count: number) => `${count}件のプロジェクト`,
    ],
    articles: [
      (count: number) => `${count} article${count > 1 ? "s" : ""}`,
      (count: number) => `${count}件の記事`,
    ],
  },
  about: {
    title: ["About me", "自己紹介"],
  },
};

export default (
  id: string,
  lang?: string,
): string | ((...args: string[]) => string) => {
  const [siteLang] = lang || useRecoilState(langAtom);

  const translation = getProperty(translations, id)?.[
    siteLang === "ja" ? 1 : 0
  ];

  return translation || (typeof translation === "function" ? () => id : id);
};
